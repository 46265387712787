<template>
  <Pages title="CXG Summary">
    <PageLandscape no-header no-footer-text>
      <div v-if="account && !loading" class="etn-report">
        <!-- Header Summary Box START -->
        <div class="bg-lighter rounded-md mb-4 p-4">
          <div class="row align-items-middle">
            <div class="col-md-4">
              <PageHeader>
                <template #top>{{ period.startDate | date('MMM YYYY') }} to {{ period.endDate | date('MMM YYYY') }}</template>
                <template #title>{{ report.metadata?.title || 'Meter Management Asds' }}</template>
                <template #bottom>{{ report.metadata?.subTitle || asset?.siteName || 'N/A' }}</template>
              </PageHeader>
            </div>

            <div class="col-md-3">
              <div>
                <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Company">{{
                  ultimateParentEntity?.legalName || 'N/A'
                }}</PropertyListItem>
                <PropertyListItem col-size="col-md-4" class="font-w600" title="Site">{{ asset?.siteName || 'N/A' }}</PropertyListItem>
              </div>
            </div>

            <div class="col-md-3">
              <PropertyListItem col-size="col-md-4" class="mb-2 font-w600" title="Period From">{{
                period.startDate || 'N/A' | date('DD MMMM YYYY')
              }}</PropertyListItem>
              <PropertyListItem col-size="col-md-4" class="font-w600" title="Period To">{{
                period.endDate || 'N/A' | date('DD MMMM YYYY')
              }}</PropertyListItem>
            </div>

            <div class="col-md-2 text-right">
              <img class="logo" :src="company.settings.reportLogoUrl || company.settings.logoUrl" />
            </div>
          </div>
        </div>
        <!-- Header Summary Box END -->

        <!-- Monthly Charges START -->
        <div v-if="analytics.consumption.accountId">
          <h5>Monthly Charges</h5>
          <div class="row">
            <div class="col-md-5">
              <HHCapacityInformation
                :period="period"
                :data="analytics.consumption"
                :prev-data="analytics.consumptionPrevYear"
              ></HHCapacityInformation>
            </div>
            <div class="col-md-7">
              <div class="bg-lighter rounded-md mb-4">
                <AccountDailyConsumption style="height: 325px" :period="period" :data="analytics.consumption.data" :units="friendlyMeterUnit" />
              </div>
              <div class="bg-lighter rounded-md">
                <AccountHourlyDemand style="height: 325px" :period="period" :data="analytics.consumption.data" :units="friendlyMeterUnit" />
              </div>
            </div>
          </div>
        </div>
        <!-- Monthly Charges END -->
      </div>
      <div v-else-if="!account && !loading">Account not found</div>

      <div v-else-if="loading" class="col-md-3 pr-4">Loading...</div>
    </PageLandscape>
  </Pages>
</template>
<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

import PageHeader from './components/PageHeader';
import PageLandscape from './components/PageLandscape';
import Pages from './components/Pages';
import PropertyListItem from '@/components/base/PropertyListItem';

import AccountDailyConsumption from './charts/AccountDailyConsumption';
import AccountHourlyDemand from './charts/AccountHourlyDemand';
import HHCapacityInformation from './tables/HHCapacityInformation';

export default {
  name: 'AssetSummaryPDF',
  components: {
    AccountDailyConsumption,
    AccountHourlyDemand,
    PageHeader,
    PageLandscape,
    HHCapacityInformation,
    Pages,
    PropertyListItem
  },
  data() {
    return {
      error: '',
      loading: true,
      period: {
        month: '2024-04',
        startDate: moment().startOf('year'),
        endDate: moment().endOf('year'),
        startDateMinusYear: moment().subtract(1, 'year').startOf('year'),
        endDateMinusYear: moment().subtract(1, 'year').startOf('year')
      },
      asset: null,
      ultimateParentEntity: null
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/account',
      company: 'company/company',
      report: 'report/report',
      analytics: 'analytics/analytics',
      accountLoading: 'account/loadingAction',
      units: 'util/units'
    }),
    friendlyMeterUnit() {
      if (this.units?.length > 0 && this.account?.meterUnits) {
        return this.units.find(u => u.value === this.account.meterUnits)?.name;
      }

      return 'N/A';
    }
  },
  async mounted() {
    const { accountId, month } = this.$route.query;

    if (!accountId) this.errorRedirect('Missing Account ID in URL');

    this.listUnits();

    await this.getAccount({ id: accountId });
    this.ultimateParentEntity = await this.getUltimateParentEntity({ id: this.account.entityId });
    this.asset = this.account.asset;

    this.period.startDate = moment(month, 'YYYY-MM');
    this.period.endDate = moment(this.period.startDate).endOf('month');

    this.period.startDateMinusYear = moment(this.period.startDate).subtract(1, 'year');
    this.period.endDateMinusYear = moment(this.period.startDateMinusYear).endOf('month');

    await this.getCompany({ id: this.asset.companyId });

    const params = {
      startDate: this.period.startDate.format('YYYY-MM-DD'),
      endDate: this.period.endDate.format('YYYY-MM-DD'),
      granularity: 'halfhourly',
      dayNight: true,
      capacityInfo: true
    };

    await Promise.all([
      this.getAnalyticsV2({
        id: 'consumption',
        params: {
          id: 'consumption',
          ...params,
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      }),
      this.getAnalyticsV2({
        id: 'consumptionPrevYear',
        params: {
          id: 'consumption',
          ...params,
          startDate: this.period.startDateMinusYear.format('YYYY-MM-DD'),
          endDate: this.period.endDateMinusYear.format('YYYY-MM-DD'),
          dataType: 'account',
          accountId: this.account._id,
          companyId: this.account.companyId
        }
      })
    ]);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getAccount: 'account/get',
      getCompany: 'company/get',
      getReport: 'report/get',
      getUltimateParentEntity: 'entity/ultimateParent',
      listUnits: 'util/listUnits',
      getAnalyticsV2: 'analytics/getAnalyticsV2'
    }),
    errorRedirect(reason) {
      this.$router.push({ name: 'machine-error', query: { errorCode: 500, errorData: { message: reason } } });
    }
  }
};
</script>
<style lang="scss">
.etn-report {
  font-size: 16px;

  table th,
  table td {
    padding: 0.3rem 0.5rem;
    height: 20px;
  }

  .logo {
    object-fit: contain;
    object-position: right center;
    height: 65px;
    width: 100%;
  }

  .divider-bottom {
    border-bottom: 3px solid #f4f4f4;
  }

  .divider-right {
    border-right: 3px solid #f4f4f4;
  }
}
</style>
