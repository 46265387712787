var render = function () {
  var _vm$ultimateParentEnt, _vm$asset2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Pages', {
    attrs: {
      "title": "CXG Summary"
    }
  }, [_c('PageLandscape', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_vm.account && !_vm.loading ? _c('div', {
    staticClass: "etn-report"
  }, [_c('div', {
    staticClass: "bg-lighter rounded-md mb-4 p-4"
  }, [_c('div', {
    staticClass: "row align-items-middle"
  }, [_c('div', {
    staticClass: "col-md-4"
  }, [_c('PageHeader', {
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate, 'MMM YYYY')) + " to " + _vm._s(_vm._f("date")(_vm.period.endDate, 'MMM YYYY')))];
      },
      proxy: true
    }, {
      key: "title",
      fn: function fn() {
        var _vm$report$metadata;

        return [_vm._v(_vm._s(((_vm$report$metadata = _vm.report.metadata) === null || _vm$report$metadata === void 0 ? void 0 : _vm$report$metadata.title) || 'Meter Management Asds'))];
      },
      proxy: true
    }, {
      key: "bottom",
      fn: function fn() {
        var _vm$report$metadata2, _vm$asset;

        return [_vm._v(_vm._s(((_vm$report$metadata2 = _vm.report.metadata) === null || _vm$report$metadata2 === void 0 ? void 0 : _vm$report$metadata2.subTitle) || ((_vm$asset = _vm.asset) === null || _vm$asset === void 0 ? void 0 : _vm$asset.siteName) || 'N/A'))];
      },
      proxy: true
    }], null, false, 180550169)
  })], 1), _c('div', {
    staticClass: "col-md-3"
  }, [_c('div', [_c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Company"
    }
  }, [_vm._v(_vm._s(((_vm$ultimateParentEnt = _vm.ultimateParentEntity) === null || _vm$ultimateParentEnt === void 0 ? void 0 : _vm$ultimateParentEnt.legalName) || 'N/A'))]), _c('PropertyListItem', {
    staticClass: "font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Site"
    }
  }, [_vm._v(_vm._s(((_vm$asset2 = _vm.asset) === null || _vm$asset2 === void 0 ? void 0 : _vm$asset2.siteName) || 'N/A'))])], 1)]), _c('div', {
    staticClass: "col-md-3"
  }, [_c('PropertyListItem', {
    staticClass: "mb-2 font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Period From"
    }
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.startDate || 'N/A', 'DD MMMM YYYY')))]), _c('PropertyListItem', {
    staticClass: "font-w600",
    attrs: {
      "col-size": "col-md-4",
      "title": "Period To"
    }
  }, [_vm._v(_vm._s(_vm._f("date")(_vm.period.endDate || 'N/A', 'DD MMMM YYYY')))])], 1), _c('div', {
    staticClass: "col-md-2 text-right"
  }, [_c('img', {
    staticClass: "logo",
    attrs: {
      "src": _vm.company.settings.reportLogoUrl || _vm.company.settings.logoUrl
    }
  })])])]), _vm.analytics.consumption.accountId ? _c('div', [_c('h5', [_vm._v("Monthly Charges")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-5"
  }, [_c('HHCapacityInformation', {
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumption,
      "prev-data": _vm.analytics.consumptionPrevYear
    }
  })], 1), _c('div', {
    staticClass: "col-md-7"
  }, [_c('div', {
    staticClass: "bg-lighter rounded-md mb-4"
  }, [_c('AccountDailyConsumption', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumption.data,
      "units": _vm.friendlyMeterUnit
    }
  })], 1), _c('div', {
    staticClass: "bg-lighter rounded-md"
  }, [_c('AccountHourlyDemand', {
    staticStyle: {
      "height": "325px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumption.data,
      "units": _vm.friendlyMeterUnit
    }
  })], 1)])])]) : _vm._e()]) : !_vm.account && !_vm.loading ? _c('div', [_vm._v("Account not found")]) : _vm.loading ? _c('div', {
    staticClass: "col-md-3 pr-4"
  }, [_vm._v("Loading...")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }